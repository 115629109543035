<script context="module">
	import Intro from './Intro.svelte';
	import Give from './Give.svelte';
	import Live from './Live.svelte';
</script>

<script>
	import { onDestroy } from 'svelte/internal';
	import Router from 'navaid';

	let Route;

	function run(Component) {
		Route = Component;
		window.scrollTo(0, 0);
	}

	const router = Router('/')
	.on('/desiri-and-kj', run.bind(null, Give))
	.on('/live', run.bind(null, Live))

	router.listen();

	onDestroy(router.unlisten);
</script>

<style lang="scss">
	main {
		display: grid;
		grid-template-rows: auto 1fr;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: 20px;

		@media (min-width: 720px) {
			padding: 32px;
		}
	}

	:global {
		body {
			background: url('/assets/background_2.jpg') no-repeat bottom center/cover fixed;
		}
	}
</style>

<main>
	<Intro/>
	<svelte:component this={Route} />
</main>
