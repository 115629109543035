<script>
    export let borderless = false;
    export let white = true;
</script>

<style lang="scss">
    .container {
		width: 88vw;

		@media (min-width: 1001px) {
			width: 60vw;
		}

		@media (min-width: 1204px) {
			width: 40vw;
		}
	}

    .block {
		border-radius: 16px;
		padding: 20px;
        background-color: black;;
		width: 100%;
        overflow: hidden;

		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &.no-border {
            padding: 0;
        }

        &.white {
		    background-color: white;
        }

		> * {
			display: grid;
			grid-template-rows: auto auto;
			grid-gap: 20px;
			justify-items: center;
		}
	}
</style>

<section class="container">
    <div class="block {borderless ? 'no-border' : ''} {white ? 'white' : ''}">
        <slot />
    </div>
</section>