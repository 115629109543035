<script context="module">
    import Container from './Container.svelte';
</script>

<script>
    const video = Promise.resolve()
        .then(async () => {
            const req = await fetch('https://sayhellotous.prismic.io/api/v2/documents/search?ref=YG2TUBMAACAAg1Rn&q=[[at(document.type, "video")]]&format=json&access_token=MC5ZRzJUZFJNQUFDQUFnMVVY.77-977-9fu-_vWZ477-977-977-9bzRwbV48B--_ve-_vTbvv71VYO-_vSd5eUws77-977-9KWM');
            const data = await req.json();
            if ('error' in data) return null;
            return data.results[0].data.live_stream.url;
        });
</script>

<style lang="scss">
    #liveVideo {   
        position: relative;
        padding-bottom: 56.2%;
        height: 0;
        overflow: hidden;
        
        iframe, video {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
            height: auto;
        }
    }

    p {
        padding: 20px;
        color: white;

        a {
            color: white;
            font-weight: 700;
        }
    }
</style>

<Container borderless white={false}>
    <div id="liveVideo">
        {#await video then video_value}
            {#if video_value !== null}
                <iframe loading="lazy" src="{video_value}" style="border:none;overflow:hidden" scrolling="no" allowfullscr
    een="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" width="1280" height="720" fra
    meborder="0"></iframe>
            {:else}
                <video controls playsinline preload width="100%">
                    <source src="/assets/intro.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                </video>
            {/if}
        {:catch e}
                <p>There seems to be an error, please head on over to<br/><a href="https://www.facebook.com/openhousecrc/videos">facebook.com/openhosecrc/videos</a><br/>to find the live stream.</p>
        {/await}
    </div>
</Container>