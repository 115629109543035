<script context="module">
	import Container from './Container.svelte';
</script>

<script>
	import { serialize, validate } from 'formee';

	const rules = {
		message(val, data) {
			// Not required
			return undefined;
		},
		amount(val, data) {
			if (val === undefined) return 'Amount is required';
		},

	};
	let success = false;
	let pp_error = '';
	let errors;
	let formValues;
	let paypalButtonRef;
	let formRef;
	const paypalLoad = () => {
		paypal.Buttons({
			style: {
				//layout: 'horizontal',
				size: 'responsive', // small | medium | large | responsive
				shape: 'rect', // pill | rect
				color: 'black', // gold | blue | silver | black
				//label: 'paypal',
				tagline: false,
				fundingicons: false,
			},
			createOrder(data, actions) {
				return actions.order.create({
					application_context: {
						user_action: 'CONTINUE',
					},
					purchase_units: [{
						description: 'Desiri and KJ, April 10 2021',
						amount: {
							current: 'AUD',
							value: formValues.amount,
						},
					}],
				});
			},
			onApprove(data, actions) {
				return actions.order.capture().then(() => {
					pp_error = '';
					success = true;
				}).catch((e) => {
					pp_error = e;
					success = false;
				});
			},
			onError() {
				success = false;
				pp_error = 'There was a PayPal error, try again.';
			},
			onClick(data, actions) {
				errors = validate(formRef, rules);
				formValues = serialize(formRef);

				return Promise.resolve()
					.then(() => {
						const formValid = !Object.values(errors).filter(Boolean).length;
						if (!formValid || Number.isNaN(Number(formValid.amount))) return actions.reject();
					})
					.then(() => fetch('/api/mail', {
						method: 'POST',
						headers: {
							'content-type': 'application/json',
						},
						body: JSON.stringify({
							message: formValues.message,
						}),
					}))
					.then(() => actions.resolve());

			},
		}).render(paypalButtonRef);
	};

	let should_bsb = false;
	const toggle_bsb = () => should_bsb = !should_bsb;
</script>

<style lang="scss">
	.input {
		outline: none;
		padding: 12px;
		border: 1px solid lightgray;
		border-radius: 5px;
		width: 100%;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&.error {
			border: 1px solid red;
		}
	}

	textarea {
		resize: vertical;
		min-height: 80px;
	}

	.paypal-button-wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 20px;
	}

	.input-wrap {
		display: grid;
		grid-auto-rows: auto;
		grid-gap: 9px;
		width: 100%;
		justify-items: center;
	}

	form {
		width: 100%;
	}

	.bsb-block {
		> p + div {
			margin-top: 10px;
		}
	}
</style>

<svelte:head>
	<script
		src="https://www.paypal.com/sdk/js?client-id=AduZwlaHbXh9MD7w1XXG9211FJzBsGfjw0jPKAvxfMnjpKHf3JlOzUD95DjAbtBkmK30rVfU2ffo0Iqq&intent=capture&currency=AUD"
		on:load={() => paypalLoad()} async defer></script>
</svelte:head>

<Container>
	{#if success}
		<p>Thank you for your gift!</p>
	{:else}
		<form bind:this={formRef}>
			{#if pp_error !== ''}
				<p>{pp_error}</p>
			{/if}
			<textarea class="input" placeholder="Would you like to leave a personal message?" name="message"/>

			<div class="input-wrap">
				<label for="give-amount">Thank you so much for your gift!</label>
				<input id="give-amount" class="input {(errors && errors['amount']) ? 'error' : ''}" placeholder="$"
						name="amount" type="number" min="1" max="9999" step="any" inputmode="decimal"
						autocomplete="transaction-amount"/>
			</div>

			<div class="paypal-button-wrapper" bind:this={paypalButtonRef}/>

			<div class="bsb-block">
				<p on:click={toggle_bsb}>Want to Bank Transfer instead? {should_bsb ? '▲' : '▼'}</p>
				{#if should_bsb}
					<div>
						<strong>Name:</strong> KJ Tromp<br/>
						<strong>BSB:</strong> 734264<br/>
						<strong>Account:</strong> 621989
					</div>
				{/if}
			</div>
		</form>
	{/if}
</Container>
